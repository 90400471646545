
.app {
  padding: 50px;
  height: 100%;
  text-align: center;
  font-family: "Helvetica Neue";

  .MuiSelect-select {
    text-align: left;
  }

  .header {
    font-size: 50px;
  }

  .container {
    margin: 3% auto;
    min-width: 300px;
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {

  .app {
    padding: 15px;
  }
  .app .container {
    width: 100%;
  }
}